




















import { Component, Emit, Prop, Vue } from "vue-property-decorator"
import { vxm } from "@/store";
import OverRayConfirm from "../OverRayConfirm.vue";
import OverRaySendPicture from "./OverRaySendPicture.vue";

@Component({
    components: {
        OverRayConfirm,
        OverRaySendPicture
    }
})
export default class CameraMode extends Vue {
    get flashIcon() {
        return vxm.controllContents.cameraFlash ? "/images/icons/Flash_on.svg" : "/images/icons/Flash_off.svg";
    }

    clickReturn() {
        this.closeCamera();
    }

    @Emit()
    closeCamera() {
        return "end";
    }

    overrayConfirm = false;
    confirmContent = {
        explanation: "撮影した画像",
        imgSrc: ""
    }
    overraySend = false;

    @Prop()
    private imageSrc!: string;

    clickShutter() {
        if((vxm.roomKey.roomNum === 3) && /_zoom/.test(this.imageSrc) && !vxm.roomKey.room3Door){
            this.overraySend = true;
            this.overrayConfirm = false;
            return
        }
        
        if((vxm.roomKey.roomNum === 4) && !vxm.roomKey.room4Door
            && vxm.controllContents.cameraFlash && vxm.controllContents.nightMode
            && vxm.controllContents.getCity?.clock == "11"
            && vxm.controllContents.brightness == 1) {
           vxm.roomKey.availableRoom4Door();
           vxm.roomKey.room4Input = {
               cameraFlash: vxm.controllContents.cameraFlash,
               nightMode: vxm.controllContents.nightMode,
               clock:  vxm.controllContents.getCity?.clock,
               brightness: vxm.controllContents.brightness
           };
           this.confirmContent.imgSrc = "/images/room4/11_non_close_L1.svg";
        } else {
            this.confirmContent.imgSrc = this.imageSrc;
        }
        this.overrayConfirm = true;
        this.overraySend = false;
    }
}
