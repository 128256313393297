


















import { Component, Emit, Vue } from 'vue-property-decorator';
import { vxm } from '@/store';

@Component
export default class OverRaySendPicture extends Vue {
    @Emit()
    closeSend() {
      return
    }

    flameHeight = "";
    flameWidth = "";
    pieceHeight = "";
    pieceWidth = "";
    mounted() {
        const flameDom: any = this.$refs.confirmWrapper;
        const height = flameDom.clientHeight * 0.3;
        const width = flameDom.clientWidth * 0.7;

        if(height > width) {
            this.flameHeight = String(width);
            this.flameWidth = String(width);
            this.pieceHeight = String(width * 0.3);
            this.pieceWidth = String(width * 0.3);
        } else {
            this.flameHeight = String(height);
            this.flameWidth = String(height);
            this.pieceHeight = String(height * 0.3);
            this.pieceWidth = String(height * 0.3);
        }

        setTimeout(this.anotherSend,900);
    }
    
    sendFlg = false;
    sendTime = 0;
    firstFlg = true;
    places = [
        { placeId: 1, image: "", pieceId: "" },
        { placeId: 2, image: "", pieceId: "" },
        { placeId: 3, image: "", pieceId: "" },
        { placeId: 4, image: "", pieceId: "" },
        { placeId: 5, image: "/images/room3/_piece/p5.png", pieceId: "5" },
        { placeId: 6, image: "", pieceId: "" },
        { placeId: 7, image: "", pieceId: "" },
        { placeId: 8, image: "", pieceId: "" },
        { placeId: 9, image: "", pieceId: "" }
    ];
    placeOrder = [1,2,3,4,6,7,8,9];
    pieceOrder = [1,2,3,4,6,7,8,9];

    selectPic(place: any) {
        if(!this.sendFlg && !place.image && place.pieceId != 5) {
            place.image = "/images/room3/_piece/p4.png";
            place.pieceId = 4;
            this.sendFlg = true;

            //今までに当てはめたピースをplace_orderとpiece_orderから除く
            const placeId = place.placeId;
            const order = this.filterOrderById(placeId,4);
            this.placeOrder = order.place;
            this.pieceOrder = order.piece;
            // 他のプレイヤーがピースを埋める
            this.othersSend(place.placeId);
        }
    }

    filterOrderById(placeId: number, pieceId: number){
      const obj: any = new Object();
      obj.place = this.placeOrder.filter(n => n !== placeId);
      obj.piece = this.pieceOrder.filter(n => n !== pieceId);
      return obj
    }

    getPlaceByPlaceId(placeId: number){
      return this.places.filter(function(place){return place.placeId == placeId})[0]
    }

    anotherSend() {
      this.places[3].pieceId = "9";
      this.places[3].image = "/images/room3/_piece/p9.png"
      const order = this.filterOrderById(4,9)
      this.placeOrder = order.place
      this.pieceOrder = order.piece
    }

    othersSend(placeId: number) {
      let placeOrder = this.placeOrder;
      let pieceOrder = this.pieceOrder;
      function send(i: number) {
        if(i > placeOrder.length - 1){ this.checkPiece(); return }
        const place = this.getPlaceByPlaceId(placeOrder[i]);
        if(!place.pieceId) {
          place.pieceId = pieceOrder[i];
          place.image = "/images/room3/_piece/p" + pieceOrder[i] + ".png";
        }
        setTimeout(send.bind(this),500,i+1);
      }
      if(this.sendTime == 0 || placeId != 4){
        placeOrder = this.shuffleOrder(placeOrder);
        pieceOrder = this.shuffleOrder(pieceOrder);
      }
      setTimeout(send.bind(this),500,0);
      this.sendTime++;
    }

    shuffleOrder(array: any) {
      for(let j = array.length - 1; j > 0; j--) {
        const r = Math.floor(Math.random() * (j + 1));
        const tmp = array[j];
        array[j] = array[r];
        array[r] = tmp;
      }
      return array
    }

    checkPiece() {
      let diff = 0;
      for(const k in this.places){
        if(this.places[k].placeId == Number(this.places[k].pieceId)){
          diff++
        }
      }
      if(diff == 9){
        vxm.roomKey.availableRoom3Door();
        vxm.roomKey.room3Diff = diff;
        this.closeSend();
      }else{
        for(const l in this.places){
          if(l == "4") { continue }
          this.places[l].pieceId = "";
          this.places[l].image = "";
        }
        this.sendFlg = false
        this.placeOrder = [1,2,3,4,6,7,8,9]
        this.pieceOrder = [1,2,3,4,6,7,8,9]
        // secondSendの内容を500おきにやる
        setTimeout(this.secondSend,500,1)
      }
    }

    secondSend(m: number) {
      if(m > 3){ return }
      const secondOrder = this.filterOrderById(4,4)
      const num = secondOrder.place[Math.floor(Math.random() * secondOrder.place.length)]
      // placesに当てはめる
      const secondPlace = this.getPlaceByPlaceId(num)
      secondPlace.pieceId = num
      secondPlace.image = "/images/room3/_piece/p" + num + ".png"
      // this.place_orderとthis.piece_orderから取り除く
      const order = this.filterOrderById(num,num)
      this.placeOrder = order.place
      this.pieceOrder = order.piece
      setTimeout(this.secondSend,500,m+1)
    }
}
