import Vue from "vue";
/* eslint-disable @typescript-eslint/ban-ts-ignore */
// @ts-ignore
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import VueHowler from 'vue-howler';
import ToggleButton from 'vue-js-toggle-button';
import 'ress';

Vue.use(VueHowler);
Vue.use(ToggleButton);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
