





































import { Component, Mixins, Watch } from "vue-property-decorator";
import { vxm } from "../store";
import SettingContentWrapper from "../components/setting/SettingContentWrapper.vue";
import SettingWifi from "../components/setting/WifiSetting.vue";
import SettingBluetooth from "../components/setting/BluetoothSetting.vue";
import SettingMusic from "../components/setting/MusicSetting.vue";
import CameraSetting from "../components/setting/CameraSetting.vue";
import NightModeSetting from "../components/setting/NightModeSetting.vue";
import ClockSetting from "../components/setting/ClockSetting.vue";
import MixinUtil from "@/mixin";

@Component({
    components: {
       SettingContentWrapper,
       SettingWifi,
       SettingBluetooth,
       SettingMusic,
       CameraSetting,
       NightModeSetting,
       ClockSetting
    }
})
export default class Setting extends Mixins(MixinUtil) {
    settings: { name: string; value: boolean; src: string }[] = [
        { name: "Wi-Fi", value: false, src: "/images/icons/Icon_wifi-1.png" },
        { name: "Bluetooth", value: false, src: "/images/icons/Icon_Bluetooth.png" },
        { name: "ミュージック", value: false, src: "/images/icons/Icon_music.png" },
        { name: "カメラ", value: false, src: "/images/icons/Icon_camera.png" },
        { name: "ナイトモード", value: false, src: "/images/icons/Icon_sleep.png" },
        { name: "日付と時刻", value: false, src: "/images/icons/Icon_clock.png" }
    ]

    created() {
        document.body.style.backgroundColor = "#EFEFF4";

        if(!vxm.roomKey.precaution) {
            this.$router.push({ path: '/' });
        }
    }

    destroyed() {
        document.body.style.backgroundColor = "#FFF";
    }

    get statusBar() {
        return vxm.controllContents.wifi ? "/images/icons/StatusBar_Wifi.png" : "/images/icons/StatusBar_npWifi.png";
    }

    returnRoom() {
        const currentRoom = "Room" + String(vxm.roomKey.roomNum);
        this.$router.push({ name: currentRoom });
    }

    @Watch('settings', { deep: true })
    resetScroll() {
        this.scrollToTop();
    }

}
