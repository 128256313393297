








import { Component, Mixins, Watch } from "vue-property-decorator";
import VueHowler from 'vue-howler';
import { vxm } from "@/store";

@Component
export default class AudioPlayer extends Mixins(VueHowler){
    volume = 0;

    mounted() {
        this.changeVolume();
    }

    get playIcon() {
        /* eslint-disable @typescript-eslint/ban-ts-ignore */
        // @ts-ignore
        return this.playing ? "/images/icons/Pass.png" : "/images/icons/Play.png";
    }

    rewindMusic() {
        vxm.controllContents.rewindMusic();
    }

    forwardMusic() {
        vxm.controllContents.forwardMusic();
    }

    get ctrlVolume() {
        return vxm.controllContents.volume;
    }

    @Watch('ctrlVolume')
    changeVolume() {
        let audioVolume: number;
        if(this.ctrlVolume == 2) {
            audioVolume = 0.0005;
        } else if(this.ctrlVolume == 3) {
            audioVolume = 0.02;
        } else if(this.ctrlVolume == 4){
            audioVolume = 0.3;
        } else {
            audioVolume = 0;
        }
        /* eslint-disable @typescript-eslint/ban-ts-ignore */
        // @ts-ignore
        this.setVolume(audioVolume);
    }
}
