import { action, createModule, mutation } from 'vuex-class-component';

const VuexModule = createModule({
    namespaced: "ControllContents",
    strict: false
});

export class ControllContents extends VuexModule {
    public wifi = false;
    public wifiPass = "nazotokio2020";
    public bluetooth = false;
    public light = false;
    public nightMode = false;
    public cameraFlash = false;
    public qr = false;
    public music = 1;
    public volume = 1;
    public brightness = 1;
    public timezone = 1;
    public cities = [
      { id: 1, name: "東京", description: "+0時間", time: "15:00", clock: "03" },
      { id: 2, name: "カイロ", description: "-7時間", time: "8:00", clock: "08" },
      { id: 3, name: "上海", description: "-1時間", time: "14:00", clock: "02" },
      { id: 4, name: "シンガポール", description: "-1時間", time: "14:00", clock: "02" },
      { id: 5, name: "パリ", description: "-7時間", time: "8:00", clock: "08" },
      { id: 6, name: "バルセロナ", description: "-7時間", time: "8:00", clock: "08" },
      { id: 7, name: "バンコク", description: "-2時間", time: "13:00", clock: "01" },
      { id: 8, name: "北京", description: "-1時間", time: "14:00", clock: "02" },
      { id: 9, name: "ミラノ", description: "-7時間", time: "8:00", clock: "08" },
      { id: 10, name: "ラスベガス", description: "-16時間", time: "23:00", clock: "11" }
    ]

    @mutation
    public changeWifiState(payload: boolean) {
        this.wifi = (payload) ? true : false;
    }

    @mutation
    public changeWifiPass(payload: string) {
        this.wifiPass = payload;
    }

    @mutation
    public changeBluetooth() {
        this.bluetooth = !this.bluetooth;
    }

    @mutation
    public offBluetooth() {
        this.bluetooth = false;
    }

    @mutation
    public changeLight() {
        this.light = !this.light;
    }

    @mutation
    public offLight() {
        this.light = false;
    }

    @mutation
    public changeNightMode() {
        this.nightMode = !this.nightMode;
    }

    @mutation
    public offNightMode() {
        this.nightMode = false;
    }

    @mutation
    public changeCameraFlash() {
        this.cameraFlash = !this.cameraFlash;
    }

    @mutation
    public changeQr() {
        this.qr = !this.qr;
    }

    @mutation
    public changeMusic(payload: number) {
        this.music = payload;
    }

    @mutation
    public rewindMusic() {
        if(this.music == 1) {
            this.music = 5;
        } else {
            this.music--;
        }
    }

    @mutation
    public forwardMusic() {
        if(this.music == 5) {
            this.music = 1;
        } else {
            this.music++;
        }
    }

    @mutation
    private upVolume() {
        if(this.volume < 4) {
            this.volume++;
        }
    }

    @mutation
    private downVolume() {
        if(this.volume > 0) {
            this.volume--;
        }
    }

    @mutation
    private upBrightness() {
        this.brightness++;
    }

    @mutation
    private downBrightness() {
        this.brightness--;
    }

    @mutation
    public changeTimeZone(payload: number) {
        this.timezone = payload;
    }

    get getCity() {
        const timezoneCity = this.cities.find((city) => city.id == this.timezone);
        return timezoneCity;
    }

    @action
    public async changeVolume(payload: boolean) {
        if(payload) {
            if(this.volume < 4) {
                this.upVolume();
            }
        } else {
            if(this.volume > 1) {
                this.downVolume();
            }
        }
    }

    @action
    public async changeBrightness(payload: boolean) {
        if(payload) {
            if(this.brightness < 3) {
                this.upBrightness();
            }
        } else {
            if(this.brightness > 1) {
                this.downBrightness();
            }
        }
    }

    @mutation
    public enterRoom4() {
        this.brightness = 3;
        this.timezone = 1;
        this.nightMode = false;
        this.cameraFlash = false;
    }
}