
























import { Component, Vue } from "vue-property-decorator";
import { vxm } from "../store";
import LastPlayerButtonWrapper from "../components/LastPlayerButtonWrapper.vue"
import OverRayConfirm from "../components/OverRayConfirm.vue";

@Component({
    components: {
        LastPlayerButtonWrapper,
        OverRayConfirm
    }
})
export default class LastStep extends Vue {
    get refs(): any {
        return this.$refs;
    }

    created() {
        if(!vxm.roomKey.precaution) {
            this.$router.push({ path: '/' });
        } else if(!vxm.roomKey.firstKey) {
            this.$router.push({ name: 'FirstStep' });
        }

        if(!vxm.controllContents.wifi) {
            this.$router.forward();
        }
        return
    }

    you = { id: 0, name: "you(プレイヤー5)", value: false };
    players = [
        { id: 1, name: "プレイヤー1", value: false },
        { id: 2, name: "プレイヤー2", value: false },
        { id: 3, name: "プレイヤー3", value: false },
        { id: 4, name: "プレイヤー4", value: false },
        { id: 5, name: "プレイヤー6", value: false },
        { id: 6, name: "プレイヤー7", value: false },
        { id: 7, name: "プレイヤー8", value: false }
    ];

    returnRoom() {
        this.$router.push({ name: 'Room1' });
    }

    overrayConfirm = false;
    confirmContent = {
        explanation: "自分のスイッチを10秒間ONにしないとクリアになりません",
        imgSrc: ""
    }
    closeMode(str: string) {
        if(str == "overInput") {
            this.overrayConfirm = true;
        }
    }

    clickReturn() {
        this.overrayConfirm = false;
    }

    toClearPage(bool: boolean) {
        if(bool) {
            vxm.roomKey.availableLastKey();
            this.$router.push({ name: 'Clear' });
        } else {
            this.overrayConfirm = true;
            this.displayAlert();
        }
    }

    displayAlert() {
        this.players.forEach((element,index) => {
            if(element.value) {
                this.refs.playerButton[index].changeTimer();
            }
        });
    }

    clearFlg = true;
    clear(bool: boolean) {
        if(this.clearFlg) {
            const time = (bool) ? 900 : 0;
            setTimeout(this.toClearPage, time, bool);
        }
        return;
    }
}
