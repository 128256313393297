






import { Component, Mixins } from "vue-property-decorator";
import VueHowler from 'vue-howler';

@Component
export default class PrecautionAudio extends Mixins(VueHowler){
    volume = 0.3;
}
