


































import { Component, Emit, Vue } from 'vue-property-decorator';
import { vxm } from "@/store";
import AudioPlayer from "./modes/AudioPlayer.vue";

@Component({
    components: {
        AudioPlayer
    }
})
export default class ControllCenter extends Vue {
    @Emit()
    closeCtrl() {
        return "ctrl";
    }

    ctrlBlockHeight = "";
    ctrlBlockWidth = "";

    mounted() {
        const ctrlContainerDom: any = this.$refs.ctrlContainer;
        const height = ctrlContainerDom.clientHeight;
        const width = ctrlContainerDom.clientWidth;
        
        if(height > width) {
            this.ctrlBlockHeight = String(width * 0.8);
            this.ctrlBlockWidth = String(width * 0.8);
        } else {
            this.ctrlBlockHeight = String(height * 0.8);
            this.ctrlBlockWidth = String(height * 0.8);
        }
    }

    get wifiIcon() {
        return (vxm.controllContents.wifi ? "/images/icons/CtrlRoom_wifi_on.png" : "/images/icons/CtrlRoom_wifi_off.png");
    }

    get bluetoothIcon() {
        return (vxm.controllContents.bluetooth ? "/images/icons/CtrlRoom_BT_on.png" : "/images/icons/CtrlRoom_BT_off.png");
    }

    get lightIcon() {
        return (vxm.controllContents.light ? "/images/icons/CtrlRoom_light_on.png" : "/images/icons/CtrlRoom_light_off.png");
    }

    get nightModeIcon() {
        return (vxm.controllContents.nightMode ? "/images/icons/CtrlRoom_sleep_on.png" : "/images/icons/CtrlRoom_sleep_off.png");
    }

    get brImageSrc() {
        return "/images/icons/CtrlRoom_sun_" + vxm.controllContents.brightness + ".png";
    }

    get volImageSrc() {
        return "/images/icons/CtrlRoom_vol_" + vxm.controllContents.volume + ".png";
    }

    toggleLight() {
        vxm.controllContents.changeLight();
    }

    toggleNightMode() {
        if(vxm.controllContents.nightMode) {
            vxm.controllContents.changeNightMode();
        } else {
            if(vxm.controllContents.getCity?.clock == "11") {
                vxm.controllContents.changeNightMode();
            } else {
                this.nightModeError();
            }
        }
    }

    @Emit()
    nightModeError() {
        return "こちらのアプリケーションは夜間のみ使用可能です。";
    }

    @Emit()
    toggleCamera() {
        return "camera";
    }

    toggleSetting() {
        this.$router.push({ name: 'Setting' });
    }

    @Emit()
    toggleClock() {
        return "clock";
    }

    @Emit()
    toggleQr() {
        return "qr";
    }

    incrementBrightness() {
        vxm.controllContents.changeBrightness(true);
    }

    decrementBrightness() {
        vxm.controllContents.changeBrightness(false);
    }

    incrementVolume() {
        vxm.controllContents.changeVolume(true);
    }

    decrementVolume() {
        vxm.controllContents.changeVolume(false);
    }

    music = [
        { id: 1, name: "SCRAMBLE", source: require("@/assets/audio/SCRAMBLE.mp3")},
        { id: 2, name: "JOKER", source: require("@/assets/audio/Joker.mp3")},
        { id: 3, name: "Room2 Door Password", source: require("@/assets/audio/Room2DoorPassword.mp3")},
        { id: 4, name: "Gear", source: require("@/assets/audio/Gear.mp3")},
        { id: 5, name: "EncounteR", source: require("@/assets/audio/EncounteR.mp3")}
    ]

    get playingAudio() {
        return this.music.find((item) => item.id == vxm.controllContents.music);
    }

    get audioSources() {
        return [this.playingAudio?.source];
    }

    get musicImageSrc() {
        return (vxm.controllContents.bluetooth) ? "/images/icons/Music_bg.png" : "/images/icons/CtrlRoom_music_0.png";
    }

    @Emit()
    togglePlayButton() {
        return "スピーカーに接続されていません。";
    }

    get bluetooth() {
        return vxm.controllContents.bluetooth;
    }
}
