





































import { Component, Emit, Vue } from "vue-property-decorator";
import { vxm } from "../../../store";

@Component
export default class ChangePass extends Vue {
    changeWifiPass = false;
    error = false;
    newPassError = false;

    @Emit()
    returnWifiSetting() {
        this.error = false;
        this.newPassError = false;
        return
    }

    changeWifiPassMode() {
        this.changeWifiPass = true;
    }

    pass="";
    newPass = "";
    submitPass() {
        this.error = false;
        this.newPassError = false;

        if(this.pass !== vxm.controllContents.wifiPass) {
            this.error = true;
        }

        if(this.newPass.length < 4 || this.newPass.length > 10) {
            this.newPassError = true;
        }

       if(!this.error && !this.newPassError) {
            vxm.controllContents.changeWifiPass(this.newPass);
            vxm.controllContents.changeWifiState(false);
            return this.returnWifiSetting();
       } else {
           this.pass = "";
           this.newPass = "";
           return
       } 
    }
}
