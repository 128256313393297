












import { Component, Mixins } from "vue-property-decorator";
import { vxm } from "../store";
import RoomTemplate from "../components/RoomTemplate.vue";
import ButtonReturn from "../components/ButtonReturn.vue";
import MixinUtil from "@/mixin";

@Component({
    components: {
        RoomTemplate,
        ButtonReturn
    }
})
export default class Room5 extends Mixins(MixinUtil) {
    mounted() {
        this.banScroll();
        vxm.roomKey.saveRoomNum(5);
    }

    beforeDestroy() {
        this.permitScroll();
    }

    banMixinScroll() {
        this.banScroll();
    }

    permitMixinScroll() {
        this.permitScroll();
    }

    created() {
        if(!vxm.roomKey.precaution) {
            this.$router.push({ path: '/' });
        } else if(!vxm.roomKey.firstKey) {
            this.$router.push({ name: 'FirstStep' });
        } else if(!vxm.roomKey.room1Key) {
            this.$router.push({ name: 'Room1' });
        } else if(!vxm.roomKey.room2Key) {
            this.$router.push({ name: 'Room2' });
        } else if(!vxm.roomKey.room3Key) {
            this.$router.push({ name: 'Room3' });
        } else if(!vxm.roomKey.room4Key) {
            this.$router.push({ name: 'Room4' });
        }
        return
    }

    overRayInput = false;
    zoom = true;
    angleNum = 1;
    isRouterActive = true;
    isZoomRouterAcive = false;
    notMode = true;

    openMode() {
        this.notMode = false;
    }

    changeNotMode() {
        this.notMode = true;
    }

    get imageSrc() {
        const angleSrcNum = ("000" + this.angleNum).slice(-3);
        return "/images/room5/Room5_" + angleSrcNum + ".svg";
    }

    closeMode(str: string) {
        if(str == "button") {
            if(this.angleNum < 8) {
                this.$router.push({ name: 'Room4' });
            } else if(this.angleNum == 17) {
                this.angleNum = 13;
                this.zoom = true;
            } else {
                this.angleNum -= 8;
                this.isRouterActive = true;
                this.isZoomRouterAcive = false;        
            }
        }
    }

    zoomAngle() {
        if(this.angleNum < 8) {
            this.angleNum += 8;
            if(this.angleNum == 5) {
                this.isRouterActive = false;
                this.isZoomRouterAcive = true;
            }
        } else if(this.angleNum == 13) {
            this.angleNum = 17;
            this.zoom = false;
        } else {
            return
        }
    }

    leftAngle() {
        if(this.angleNum == 1 || this.angleNum == 9) {
            this.angleNum += 6;
        } else if(this.angleNum == 17) {
            this.zoom = true;
        } else {
            if(this.angleNum == 13) {
                this.isRouterActive = false;
                this.isZoomRouterAcive = false;        
            } else if(this.angleNum == 15){
                this.isRouterActive = false;
                this.isZoomRouterAcive = true;        
            }
            this.angleNum -= 2;
        }
    }

    rightAngle() {
        if(this.angleNum == 7 || this.angleNum == 15) {
            this.angleNum -= 6;
        } else if(this.angleNum == 17) {
            this.zoom = true;
        } else {
            if(this.angleNum == 13) {
                this.isRouterActive = false;
                this.isZoomRouterAcive = false;
            } else if(this.angleNum == 11){
                this.isRouterActive = false;
                this.isZoomRouterAcive = true;
            }
            this.angleNum += 2;
        }
    }
}
