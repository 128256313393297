

















import { Component, Prop, Vue } from "vue-property-decorator"
import { vxm } from "@/store";

type NightMode = {
    name: string;
    value: boolean;
}

@Component
export default class NightModeSetting extends Vue {
    error = false;

    @Prop()
    private nightMode!: NightMode;

    returnSetting() {
        this.nightMode.value = false;
    }

    get nightModeState() {
        return vxm.controllContents.nightMode;
    }

    toggleButton() {
        if(vxm.controllContents.timezone == 10) {
            vxm.controllContents.changeNightMode();
        } else {
            this.error = true;
        }
    }
}
