
















import { Component, Prop, Vue } from "vue-property-decorator"
import { vxm } from "@/store";

type Camera = {
    name: string;
    value: boolean;
}

@Component
export default class CameraSetting extends Vue {
    @Prop()
    private camera!: Camera;

    returnSetting() {
        this.camera.value = false;
    }
   
   get flash() {
       return vxm.controllContents.cameraFlash;
   }

   toggleButton() {
       vxm.controllContents.changeCameraFlash();
   }
}
