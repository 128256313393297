
























import { Component, Emit, Prop, Vue } from "vue-property-decorator"
import { vxm } from "@/store";
import OverRayConfirm from "../OverRayConfirm.vue";

@Component({
    components: {
        OverRayConfirm
    }
})
export default class QrMode extends Vue {
    @Prop()
    private imageSrc!: string;

    get qrZone() {
        return (vxm.roomKey.roomNum === 1) && /_zoom/.test(this.imageSrc) && vxm.controllContents.brightness > 1 ? true : false;
    }

    overrayConfirm = false;
    confirmContent = {
        explanation: "読み取ることができませんでした",
    }

    clickReturn() {
        this.closeQr();
    }

    @Emit()
    closeQr() {
        return "end";
    }

    private judgeQr(areaNum: number) {
        if(areaNum == 9) {
            if(vxm.controllContents.wifi) {
                this.$router.push({ name: 'LastStep' });
                return
            } else {
                this.confirmContent.explanation = 'yosen4_clearpage/..../.comは、インターネットに接続されていないため開くことができません';
            }
        } else if(areaNum == 5) {
            this.confirmContent.explanation = '「DOOR="130"」';
        } else if(areaNum > 0) {
            const qrNum = Math.abs(28- areaNum ^2) * 26 + 52;
            this.confirmContent.explanation = '「DOOR="' + qrNum + '"」';
        } else if(areaNum == 0) {
            if(vxm.controllContents.brightness == 1 || /L0/.test(this.imageSrc)) {
                this.confirmContent.explanation = "暗いため読み取ることができませんでした";
            } else if(!/(_zoom)/.test(this.imageSrc) && (vxm.roomKey.roomNum === 1)) {
                this.confirmContent.explanation = "近づいてください";
            }
        }

        this.overrayConfirm = true;
    }
}
