






















import { Component, Vue } from "vue-property-decorator";

const requireComponent = require.context(
    "../../public/images",
    true,
    /[A-Z]\w+\.(png|svg)$/
);

@Component
export default class Loading extends Vue {
  mounted(){
    const images = requireComponent.keys().map((value) => {
        return value = "/images" + value.slice(1)
    });

    for(let i=0; i<images.length; i++) {
        const img = document.createElement('img');
        img.src = images[i];
    }
    this.$emit('loaded');
  }
}
