import { render, staticRenderFns } from "./OverRayConfirm.vue?vue&type=template&id=0223fe90&scoped=true&"
import script from "./OverRayConfirm.vue?vue&type=script&lang=ts&"
export * from "./OverRayConfirm.vue?vue&type=script&lang=ts&"
import style0 from "./OverRayConfirm.vue?vue&type=style&index=0&id=0223fe90&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0223fe90",
  null
  
)

export default component.exports