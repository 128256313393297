










import { Component, Mixins } from "vue-property-decorator";
import { vxm } from "../store";
import RoomTemplate from "../components/RoomTemplate.vue";
import ButtonReturn from "../components/ButtonReturn.vue";
import MixinUtil from "@/mixin";

@Component({
    components: {
        RoomTemplate,
        ButtonReturn
    }
})
export default class Room4 extends Mixins(MixinUtil) {
    mounted() {
        this.banScroll();
        vxm.roomKey.saveRoomNum(4);
    }

    beforeDestroy() {
        this.permitScroll();
    }

    banMixinScroll() {
        this.banScroll();
    }

    permitMixinScroll() {
        this.permitScroll();
    }

    created() {
        if(!vxm.roomKey.precaution) {
            this.$router.push({ path: '/' });
        } else if(!vxm.roomKey.firstKey) {
            this.$router.push({ name: 'FirstStep' });
        } else if(!vxm.roomKey.room1Key) {
            this.$router.push({ name: 'Room1' });
        } else if(!vxm.roomKey.room2Key) {
            this.$router.push({ name: 'Room2'});
        } else if(!vxm.roomKey.room3Key) {
            this.$router.push({ name: 'Room3' });
        }
    }

    notMode = true;

    openMode() {
        this.notMode = false;
    }

    changeNotMode() {
        this.notMode = true;
    }

    get imageSrc() {
        if(vxm.roomKey.room4Door) {
            return "/images/room4/" +
                vxm.controllContents.getCity?.clock + 
                (vxm.controllContents.getCity?.clock == "11" && vxm.controllContents.nightMode ? "_non_" : "_noff_") +
                (vxm.roomKey.room4Key ? "open" : "close") + "_L" + vxm.controllContents.brightness + ".svg";
        }

        return "/images/room4/" +
            vxm.controllContents.getCity?.clock + 
            (vxm.controllContents.getCity?.clock == "11" && vxm.controllContents.nightMode && vxm.controllContents.brightness == 1 ? "_non" : "") +
            "_L" + vxm.controllContents.brightness + ".svg";
    }

    closeMode(str: string) {
        if(str == "button") {
            this.$router.push({ name: 'Room3' });
        }
    }

    get room4Door() {
        return vxm.roomKey.room4Door;
    }

    toNextPage() {
        this.$router.push({ name: 'Room5' });
    }
    
    clickDoor() {
        if(vxm.roomKey.room4Key) {
            setTimeout(this.toNextPage, 1500);
        } else {
            vxm.roomKey.availableRoom4Key();
        }
    }
}
