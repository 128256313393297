






















import { Component, Mixins } from "vue-property-decorator";
import { vxm } from "../store";
import RoomTemplate from "../components/RoomTemplate.vue";
import OverRayInput from "../components/OverRayInput.vue";
import ButtonReturn from "../components/ButtonReturn.vue";
import MixinUtil from "@/mixin";
import { hashText } from "@/store/modules/hash";

@Component({
  components: {
    RoomTemplate,
    OverRayInput,
    ButtonReturn,
  },
})
export default class Room2 extends Mixins(MixinUtil) {
  mounted() {
    this.banScroll();
    vxm.roomKey.saveRoomNum(2);
  }

  beforeDestroy() {
    this.permitScroll();
  }

  banMixinScroll() {
    this.banScroll();
  }

  permitMixinScroll() {
    this.permitScroll();
  }

  overRayInput = false;
  inputContent = {
    explanation: "ドアのパスワードを入力",
    mistake: "",
  };
  returnButtonName = "room";
  notMode = true;

  openMode() {
    this.notMode = false;
  }

  changeNotMode() {
    this.notMode = true;
  }

  created() {
    if (!vxm.roomKey.precaution) {
      this.$router.push({ path: "/" });
    } else if (!vxm.roomKey.firstKey) {
      this.$router.push({ name: "Firststep" });
    } else if (!vxm.roomKey.room1Key) {
      this.$router.push({ name: "Room1" });
    }
    return;
  }

  get imageSrc() {
    if (vxm.roomKey.room2Key) {
      return "/images/room2/L" + vxm.controllContents.brightness + "_open.svg";
    } else {
      return "/images/room2/L" + vxm.controllContents.brightness + ".svg";
    }
  }

  returnRoom1() {
    this.$router.push({ name: "Room2" });
  }

  closeMode(str: string) {
    if (str == "overInput") {
      this.overRayInput = false;
    }

    if (str == "button") {
      vxm.controllContents.offBluetooth();
      this.$router.push({ name: "Room1" });
    }
  }

  toNextRoom() {
    this.$router.push({ name: "Room3" });
  }

  clickDoor() {
    if (vxm.roomKey.room2Key) {
      vxm.controllContents.offBluetooth();
      setTimeout(this.toNextRoom, 1500);
    } else {
      this.overRayInput = true;
    }
  }

  checkAnswer(answer: string) {
    const hashed = hashText(answer);
    if (
      [
        "28ac593455c7f44e49e0e5fb2b130739cd8aed87c15e4d79eb2fc69c47649360",
        "cb75da5e44e9d24a649ceee58ff46089e6560cb9b0156cd2c4383f519e134b39",
      ].includes(hashed)
    ) {
      vxm.roomKey.room2Answer = answer;
      vxm.roomKey.availableRoom2Key();
      this.closeMode("overInput");
    } else {
      this.inputContent.mistake = "パスワードが違います";
    }
  }
}
