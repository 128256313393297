












import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

type ConfirmContent = {
    explanation: string;
    imgSrc: string;
}

@Component
export default class OverRayConfirm extends Vue {
    @Prop()
    private confirmContent!: ConfirmContent;

    @Emit()
    closeConfirm() {
        return
    }
    
    imgMaxHeight = "";

    mounted() {
        const overrayContainerDom: any = this.$refs.overrayContainer;
        const height = overrayContainerDom.clientHeight;
        this.imgMaxHeight = String(height * 0.6);
    }
}
