





























import { Component, Prop, Vue } from "vue-property-decorator";
import { vxm } from "../../store";
import WifiInputPass from "./WifiSettings/WifiInputPass.vue";
import ChangePass from "./WifiSettings/ChangePass.vue";

type Wifi = {
    name: string;
    value: boolean;
}

@Component({
    components: {
        WifiInputPass,
        ChangePass
    }
})
export default class WifiSetting extends Vue {
    @Prop()
    private wifi!: Wifi;

    get wifiConnection() {
        if(vxm.controllContents.wifi) {
            return "Yosen4_groupA"
        } else {
            return "未接続"
        }
    }

    returnSetting() {
        this.wifi.value = false;
    }

    wifiPass = false;
    changePass = false;

    connectWifi() {
        this.wifiPass = true;
        this.scrollToTop();
    }

    changeWifiPass() {
        this.changePass = true;
        this.scrollToTop();
    }

    returnWifiSetting() {
        this.wifiPass = false;
        this.changePass = false;
    }

    scrollToTop() {
        window.scrollTo({
           top: 0
        })
    }

    get isWifiConnect() {
        return vxm.controllContents.wifi;
    }
}
