






















import { Component, Watch, Vue } from "vue-property-decorator";
import { vxm } from "../store";
import PlayerButtonWrapper from "../components/PlayerButtonWrapper.vue"

@Component({
    components: {
        PlayerButtonWrapper
    }
})
export default class FirstStep extends Vue {
    created() {
        if(!vxm.roomKey.precaution) {
            this.$router.push({ path: '/' });
        }
    }

    counter = 0;
    playerCounter = 1;
    you = { id: 0, name: "you(プレイヤー5)", value: false };
    players = [
        { id: 1, name: "プレイヤー1", value: false },
        { id: 2, name: "プレイヤー2", value: false },
        { id: 3, name: "プレイヤー3", value: false },
        { id: 4, name: "プレイヤー4", value: false },
        { id: 5, name: "プレイヤー6", value: false },
        { id: 6, name: "プレイヤー7", value: false },
        { id: 7, name: "プレイヤー8", value: false }
    ];

    countToggle(value: boolean) {
        if(this.counter < 8) {
            value ? this.counter++ : this.counter--;
        }
    }

    setSwitch(num: number) {
        if(!this.players[num].value) {
            this.players[num].value = true;
            this.countToggle(this.players[num].value);
        }
    }

    toNextRoom() {
        this.$router.push({ name: 'Room1' }).catch(() => {/* catch error */});
    }

    @Watch('counter')
    checkCounterNum() {
        if(this.counter >= 8) {
            vxm.roomKey.availableFirstKey();
            setTimeout(this.toNextRoom, 1500);
        }
    }

    mounted() {
        for(let l=0; l<7; l++) {
            const randomTime = Math.floor(Math.random() * (5 - 1) + 6) * 400;
            setTimeout(()=>{this.playerCounter++;}, randomTime);
        }

        for(const player in this.players) {
            const randomTime = Math.floor(Math.random() * (10 - 2) + 6) * 400;
            if(this.players[player].id !== 4) {
                setTimeout(this.setSwitch, randomTime, player);
            }
        }
        
        vxm.roomKey.saveRoomNum(6);
    }
}
