











import { Component, Prop, Vue } from "vue-property-decorator";
import { vxm } from "@/store";

type Setting = {
   name: string;
   value: boolean;
   src: string;
}

@Component
export default class SettingContentWrapper extends Vue {
   @Prop()
   private setting!: Setting;

   changeSettingContent() {
      this.setting.value = true;
   }

   get status() {
      if(this.setting.name == "Wi-Fi") {
         return vxm.controllContents.wifi ? "Yosen4_groupA" : "未接続";
      }

      if(this.setting.name == "Bluetooth") {
         return vxm.controllContents.bluetooth ? "Room02 speaker" : "未接続";
      }

      return "";
   }
}
