












import { Component, Prop, Vue } from "vue-property-decorator";
import ControllCenter from "./ControllCenter.vue";
import CameraMode from "./modes/CameraMode.vue";
import QrMode from "./modes/QrMode.vue";
import ClockMode from "./modes/ClockMode.vue";
import OverRayConfirm from "./OverRayConfirm.vue";

@Component({
    components: {
        ControllCenter,
        CameraMode,
        QrMode,
        ClockMode,
        OverRayConfirm
    }
})
export default class RoomTemplate extends Vue {
    ctrlCenter = false;
    cameraMode = false;
    qrMode = false;
    clockMode = false;
    overrayConfirm = false;
    confirmContent = {
        explanation: "",
        imgSrc: ""
    }
    activeMode = false;

    clickCtrlButton() {
        this.ctrlCenter = true;
    }

    @Prop()
    public imageSrc!: string;

    changeMode(str: string) {
        if(str == "clock-end"){
            this.$emit("banScroll");
        }

        if(str == "camera") {
            this.ctrlCenter = false;
            this.cameraMode = true;
            this.qrMode = false;
            this.clockMode = false;
            this.overrayConfirm = false; 
            this.$emit("openMode");
            this.activeMode = true;
        } else if(str =="qr") {
            this.ctrlCenter = false;
            this.cameraMode = false;
            this.qrMode = true;
            this.clockMode = false;
            this.overrayConfirm = false; 
            this.$emit("openMode");
            this.activeMode = true;
        } else if(str =="clock") {
            this.ctrlCenter = false;
            this.cameraMode = false;
            this.qrMode = false;
            this.clockMode = true;
            this.overrayConfirm = false; 
            this.$emit("openMode");
            this.$emit("permitScroll");
            this.activeMode = true;
        } else if(str == "ctrl" || str == "end" || str == "clock-end") {
            this.ctrlCenter = false;
            this.cameraMode = false;
            this.qrMode = false;
            this.clockMode = false;
            this.overrayConfirm = false; 
            this.$emit("closeMode");
            this.activeMode = false;
        }
    }

    clickReturn() {
        this.changeMode("end");
    }

    openOverrayConfirm(str: string) {
        this.confirmContent.explanation = str;
        this.ctrlCenter = false;
        this.cameraMode = false;
        this.qrMode = false;
        this.overrayConfirm = true;
    }

    
}
