import Vue from "vue";
import Vuex from "vuex";
import { createProxy, extractVuexModule } from "vuex-class-component";
import createPersistedState from "vuex-persistedstate";
import { RoomKeys } from "./modules/roomkeys";
import { ControllContents } from "./modules/controllcontents";
import firebase from "firebase";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB5b4xGwCsBfw38sGR4bTkcKrptQM9MnUY",
  authDomain: "nazo2020-dc-dev.firebaseapp.com",
  projectId: "nazo2020-dc-dev",
  storageBucket: "nazo2020-dc-dev.appspot.com",
  messagingSenderId: "820818455477",
  appId: "1:820818455477:web:e6d32f1291121dfd3187fc",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

Vue.use(Vuex);

export const store = new Vuex.Store({
  plugins: [createPersistedState()],

  modules: {
    ...extractVuexModule(RoomKeys),
    ...extractVuexModule(ControllContents),
  },
});

export const vxm = {
  roomKey: createProxy(store, RoomKeys),
  controllContents: createProxy(store, ControllContents),
};
