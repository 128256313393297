



















import { Component, Emit, Vue } from "vue-property-decorator";
import { vxm } from "../../../store";

@Component
export default class WifiInputPass extends Vue {
    error = false;

    @Emit()
    returnWifiSetting() {
        this.error = false;
        return
    }

    pass="";
    submitPass() {
        if(this.pass == vxm.controllContents.wifiPass) {
            vxm.controllContents.changeWifiState(true);
            this.returnWifiSetting();
        } else {
            this.pass = "";
            this.error = true;
        }
    }
}
