














import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

type InputContent = {
    explanation: string;
    mistake: string;
}

@Component
export default class OverRayInput extends Vue {
    @Prop()
    private inputContent!: InputContent;

    inputText = "";

    @Emit()
    closeInput() {
        if(this.inputContent.mistake) {
            this.inputContent.mistake = "";
        }
        return "overInput";
    }

    @Emit()
    submitAnswer() {
        return this.inputText;
    }
}
