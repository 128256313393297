






import { Component, Vue } from "vue-property-decorator";
import { vxm } from "../store";
import firebase from "firebase";

@Component
export default class Clear extends Vue {
  mounted() {
    console.log(vxm.roomKey);
    const {
      precaution,
      firstKey,
      room1Key,
      room1LightSwitch,
      room2Key,
      room3Key,
      enterRoom4,
      room4Door,
      room5Key,
      lastKey,
      roomNum,
      room1Answer,
      room2Answer,
      room3Diff,
      room4Input,
    } = vxm.roomKey;

    const req = {
      precaution,
      firstKey,
      room1Key,
      room1LightSwitch,
      room2Key,
      room3Key,
      enterRoom4,
      room4Door,
      room5Key,
      lastKey,
      roomNum,
      room1Answer,
      room2Answer,
      room3Diff,
      room4Input,
    };

    const functions = firebase.functions();
    const getClearUrl = functions.httpsCallable("getClearUrlDev");
    getClearUrl(req).then((res: any) => {
      console.log(res.data);
      location.href = res.data.url;
    });
  }
}
