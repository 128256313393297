import { Component, Vue } from "vue-property-decorator";

@Component
export default class MixinUtil extends Vue {
    /**
     *  スクロール禁止
     * 
     *  */
    noscroll (event: any) { 
        event.preventDefault();
    }

    banScroll() {
        document.addEventListener('touchmove', this.noscroll, { passive: false });
        document.addEventListener('wheel', this.noscroll, { passive: false });
    }

    permitScroll() {
        document.removeEventListener('touchmove', this.noscroll);
        document.removeEventListener('wheel', this.noscroll);
    }
    /**
    *
    * 
    */

    /**
     * スクロール量を0にリセット
     * 
     */
    scrollToTop() {
        window.scrollTo(0,0);
    }
}