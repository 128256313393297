



















import { Component, Emit, Vue } from "vue-property-decorator";
import { vxm } from "@/store";

@Component
export default class ClockMode extends Vue {
    created() {
        document.body.style.backgroundColor = "#000";
    }

    destroyed() {
        document.body.style.backgroundColor = "#FFF";
    }

    clickReturn() {
        this.closeClock();
    }

    @Emit()
    closeClock() {
        return "clock-end";
    }

    get cities() {
        return vxm.controllContents.cities;
    }
}
