






import { Component, Emit, Vue } from 'vue-property-decorator';

@Component
export default class ButtonReturn extends Vue {
    @Emit()
    clickReturn() {
        return "button";
    }
}
