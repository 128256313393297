import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Precautions from "../views/Precautions.vue"
import FirstStep from "../views/FirstStep.vue";
import Room1 from "../views/Room1.vue";
import Room2 from "../views/Room2.vue";
import Room3 from "../views/Room3.vue";
import Room4 from "../views/Room4.vue";
import Room5 from "../views/Room5.vue";
import LastStep from "../views/LastStep.vue";
import Setting from "../views/Setting.vue";
import Clear from "../views/Clear.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  { path: "/", name: "Precautions", component: Precautions },
  { path: "/m6M2ywPYwKBtEiW5aFTRPTylrWSrFs", name: "FirstStep", component: FirstStep },
  { path: "/OsfAOCLN58lGo25abb7vV24ATlPW1C", name: "Room1", component: Room1 },
  { path: "/kIHx2T83LKFtGCpjeaNM587VBkIB2E", name: "Room2", component: Room2 },
  { path: "/iYKYbu6VR3gNv5pFOuNwLpYxwosv3H", name: "Room3", component: Room3 },
  { path: "/to4JaThxnQQGKLA380GNmBmx7vE64G", name: "Room4", component: Room4 },
  { path: "/pwWv570tOOyOoNAbyp3VSbfaMP3X5e", name: "Room5", component: Room5 },
  { path: "/o51i2obwENFU1rNJjQSLXib2T0nPLn", name: "LastStep", component: LastStep },
  { path: "/p7VLq47mdC05nCLG6PttbRH6O3weS5", name: "Setting", component: Setting },
  { path: "/fBpJMUgMljcC76ECcGtJIrvXILtFCE", name: "Clear", component: Clear },
  { path: "*", redirect: "/" }
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
