





























import MixinUtil from "@/mixin";
import { Component, Mixins, Watch } from "vue-property-decorator";
import ButtonReturn from "../components/ButtonReturn.vue";
import OverRayInput from "../components/OverRayInput.vue";
import RoomTemplate from "../components/RoomTemplate.vue";
import { vxm } from "../store";
import { hashText } from "../store/modules/hash";

@Component({
  components: {
    RoomTemplate,
    OverRayInput,
    ButtonReturn,
  },
})
export default class Room1 extends Mixins(MixinUtil) {
  mounted() {
    this.scrollToTop();
    this.banScroll();
    vxm.roomKey.saveRoomNum(1);
  }

  beforeDestroy() {
    this.permitScroll();
  }

  banMixinScroll() {
    this.banScroll();
  }

  permitMixinScroll() {
    this.permitScroll();
  }

  overRayInput = false;
  get roomLightSwitch() {
    return vxm.roomKey.room1LightSwitch;
  }
  imageSrc = "";
  zoom = false;
  inputContent = {
    explanation: "ドアのパスワードを入力",
    mistake: "",
  };
  notMode = true;

  openMode() {
    this.notMode = false;
  }

  changeNotMode() {
    this.notMode = true;
  }

  created() {
    if (!vxm.roomKey.precaution) {
      this.$router.push({ path: "/" });
    } else if (!vxm.roomKey.firstKey) {
      this.$router.push({ name: "FirstStep" });
    }
    this.changeImageSrc();
    return;
  }

  closeMode(str: string) {
    if (str == "overInput") {
      this.overRayInput = false;
    }

    if (str == "button") {
      this.zoom = false;
    }
  }

  changeImageSrc() {
    if (!vxm.roomKey.room1LightSwitch) {
      if (vxm.controllContents.light) {
        this.imageSrc = "/images/room1/L0_light.svg";
      } else {
        this.imageSrc = "/images/room1/L0.svg";
      }
    } else {
      if (this.zoom) {
        this.imageSrc =
          "/images/room1/L" + vxm.controllContents.brightness + "_zoom.svg";
      } else if (vxm.roomKey.room1Key) {
        this.imageSrc =
          "/images/room1/L" + vxm.controllContents.brightness + "_open.svg";
      } else {
        this.imageSrc =
          "/images/room1/L" + vxm.controllContents.brightness + ".svg";
      }
    }
  }

  get ctrlLight() {
    return vxm.controllContents.light;
  }

  clickMap() {
    this.zoom = true;
    return;
  }

  toNextRoom() {
    this.$router.push({ name: "Room2" }).catch(() => {
      /* catch error */
    });
  }

  clickDoor() {
    if (vxm.roomKey.room1Key) {
      setTimeout(this.toNextRoom, 1500);
    } else {
      this.overRayInput = true;
    }
    return;
  }

  clickSwitch() {
    vxm.roomKey.availableRoom1LightSwitch();
    return;
  }

  get lightState() {
    return vxm.controllContents.light;
  }

  get brightnessState() {
    return vxm.controllContents.brightness;
  }

  @Watch("roomLightSwitch")
  checkSwitchValue() {
    this.changeImageSrc();
  }

  @Watch("lightState")
  checkLightState() {
    this.changeImageSrc();
  }

  @Watch("brightnessState")
  checkBrightnessState() {
    this.changeImageSrc();
  }

  @Watch("zoom")
  checkZoomState() {
    this.changeImageSrc();
  }

  // 答え合わせ関数
  checkAnswer(answer: string) {
    const hashed = hashText(answer);
    if (
      [
        "38d66d9692ac590000a91b03a88da1c88d51fab2b78f63171f553ecc551a0c6f",
        "25912d14de4c4e38f8e26c1a22708afaa5ee0c789ad4a7137118798b7131f265",
      ].includes(hashed)
    ) {
      vxm.roomKey.room1Answer = answer;
      vxm.roomKey.availableRoom1Key();
      this.changeImageSrc();
      this.closeMode("overInput");
    } else {
      this.inputContent.mistake = "パスワードが違います";
    }
  }
}
