






















import { Component, Prop, Vue } from "vue-property-decorator"
import { vxm } from "@/store";

type Bluetooth = {
    name: string;
    value: boolean;
}

@Component
export default class BluetoothSetting extends Vue {
    @Prop()
    private bluetooth!: Bluetooth;

    currentRoom = false;

    created() {
        if(vxm.roomKey.roomNum === 2) {
            this.currentRoom = true;
        }
    }

    returnSetting() {
        this.bluetooth.value = false;
    }

    get connectStatus() {
        return (vxm.controllContents.bluetooth ? "接続済み" : "未接続");
    }

    toggleButton() {
        vxm.controllContents.changeBluetooth();
    }
}
