












































import { Component, Vue } from "vue-property-decorator";
import { vxm } from "../store";
import Loading from "../components/Loading.vue"
import PrecautionAudio from "../components/PrecautionAudio.vue"

@Component({
    components: {
        Loading,
        PrecautionAudio
    }
})
export default class Precautions extends Vue {
    created() {
        if(vxm.roomKey.roomNum === 0) {
            return
        } else if(vxm.roomKey.roomNum < 6) {
            const currentRoom = "Room" + String(vxm.roomKey.roomNum);
            this.$router.push({ name: currentRoom });
        } else {
            this.$router.push({ name: 'FirstStep' });
        }
    }

    loading = false;
    results = [];
    audioSource = [require("@/assets/audio/Experiment-α・β.mp3")]

    clickStart() {
        const check1 = document.getElementById("check-1");
        const check2 = document.getElementById("check-2");
        /* eslint-disable @typescript-eslint/ban-ts-ignore */
        // @ts-ignore
        if(check1.checked && check2.checked) {
            vxm.roomKey.availablePrecaution();
            this.loading = true;
        }
    }

    loaded() {
        setTimeout(()=>{this.$router.push({ name: 'FirstStep'})}, 5000);
    }
}
