



























import { Component, Prop, Vue } from 'vue-property-decorator';
import { vxm } from "../../store";
import TimeZoneSetting from "../setting/TimeZoneSetting.vue";

type Clock = {
    name: string;
    value: boolean;
}

@Component({
    components: {
        TimeZoneSetting
    }
})
export default class ClockSetting extends Vue {
    timeZone = false;

    @Prop()
    private clock!: Clock;

    returnSetting() {
        this.clock.value = false;
    }

    returnClockSetting() {
        this.timeZone = false;
    }

    get city() {
        return vxm.controllContents.getCity;
    }

    changeTimeZone() {
        this.timeZone = true;
    }
}
