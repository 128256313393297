





















import { Component, Emit, Vue } from 'vue-property-decorator';
import { vxm } from "../../store";

@Component
export default class TimeZoneSetting extends Vue {
    @Emit()
    returnClockSetting() {
        return
    }

    get timezoneCity() {
        return vxm.controllContents.getCity;
    }

    get cityList() {
        const cityList = vxm.controllContents.cities.filter(city => {
            return (city.id != vxm.controllContents.timezone);
        })
        return cityList;
    }

    changeTimeZone(cityId: number) {
        vxm.controllContents.changeTimeZone(cityId);
        if(cityId != 10) {
            vxm.controllContents.offNightMode();
        }
        this.returnClockSetting();
    }
}
