







import { Component, Prop, Vue } from "vue-property-decorator";

type Player = {
    id: number;
    name: string;
    value: boolean;
}

@Component
export default class PlayerButtonWrapper extends Vue {
    @Prop()
    private player!: Player;

    toggleButton() {
        this.player.value = !this.player.value;
        this.$emit("playerToggle", this.player.value);
    }
}
