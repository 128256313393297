















import { Component, Prop, Vue } from "vue-property-decorator";

type Music = {
    name: string;
    value: boolean;
}

@Component
export default class MusicSetting extends Vue {
    @Prop()
    private music!: Music;

    playlist: { id: number; name: string }[] = [
        { id: 0, name: "SCRAMBLE" },
        { id: 1, name: "JOKER" },
        { id: 2, name: "Room2 Door Password" },
        { id: 3, name: "Gear" },
        { id: 4, name: "EncounteR" }
    ]

    returnSetting() {
        this.music.value = false;
    }
}
