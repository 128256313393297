import { render, staticRenderFns } from "./ClockMode.vue?vue&type=template&id=8e7ffa7e&scoped=true&"
import script from "./ClockMode.vue?vue&type=script&lang=ts&"
export * from "./ClockMode.vue?vue&type=script&lang=ts&"
import style0 from "./ClockMode.vue?vue&type=style&index=0&id=8e7ffa7e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e7ffa7e",
  null
  
)

export default component.exports