











import { Component, Watch, Mixins } from "vue-property-decorator";
import { vxm } from "../store";
import RoomTemplate from "../components/RoomTemplate.vue";
import OverRayInput from "../components/OverRayInput.vue";
import ButtonReturn from "../components/ButtonReturn.vue";
import MixinUtil from "@/mixin";

@Component({
    components: {
        RoomTemplate,
        OverRayInput,
        ButtonReturn
    }
})
export default class Room3 extends Mixins(MixinUtil) {
    mounted() {
        this.banScroll();
        vxm.roomKey.saveRoomNum(3);
    }

    beforeDestroy() {
        this.permitScroll();
    }

    banMixinScroll() {
        this.banScroll();
    }

    permitMixinScroll() {
        this.permitScroll();
    }

    zoom = false;
    doorOpen = false;
    doorFlg = false;
    notMode = true;

    openMode() {
        this.notMode = false;
    }

    changeNotMode() {
        this.notMode = true;
    }

    created() {
        if(!vxm.roomKey.precaution) {
            this.$router.push({ path: '/' });
        } else if(!vxm.roomKey.firstKey) {
            this.$router.push({ name: 'FirstStep' });
        } else if(!vxm.roomKey.room1Key) {
            this.$router.push({ name: 'Room1' });
        } else if(!vxm.roomKey.room2Key) {
            this.$router.push({ name: 'Room2' });
        }
        return
    }

    get imageSrc() {
        if(vxm.roomKey.room3Key) {
            if(this.zoom) {
                return "/images/room3/L" + vxm.controllContents.brightness + "_zoom.svg";
            } else {
                return "/images/room3/L" + vxm.controllContents.brightness + "_open.svg";
            }
        } else if(vxm.roomKey.room3Door) {
            if(this.doorOpen) {
                if(this.zoom) {
                    return "/images/room3/L" + vxm.controllContents.brightness + "_zoom.svg";
                } else {
                    return "/images/room3/L" + vxm.controllContents.brightness + "_Door.svg";
                }
            } else {
                if(this.zoom) {
                    return "/images/room3/L" + vxm.controllContents.brightness + "_zoom.svg";
                } else {
                    return "/images/room3/L" + vxm.controllContents.brightness + "_Frame_Door.svg";
                }
            }
        } else {
            if(this.zoom) {
                return "/images/room3/L" + vxm.controllContents.brightness + "_zoom.svg";
            } else {
                return "/images/room3/L" + vxm.controllContents.brightness + "_Frame.svg";
            }
        }
    }

    closeMode(str: string) {
        if(str == "button") {
            if(this.zoom) {
                this.zoom = false;
            } else {
                this.$router.push({ name: 'Room2' });
            }
        }
    }

    clickWall() {
        this.zoom = true;
    }

    toNextRoom() {
        vxm.roomKey.checkEnterRoom4State();
        this.$router.push({ name: 'Room4' });
    }
    
    clickDoor() {
        if(vxm.roomKey.room3Key) {
            setTimeout(this.toNextRoom, 1500);
        } else if(this.doorOpen) {
            vxm.roomKey.availableRoom3Key();
        } else if(vxm.roomKey.room3Door) {
            this.doorOpen = true;
        }
    }

    get doorState() {
        return vxm.roomKey.room3Door;
    }

    @Watch('doorState')
    checkDoorFlg() {
        if(!this.doorFlg) {
            this.zoom = false;
            this.doorFlg = true;
        }
    }
}
