import { action, createModule, mutation } from "vuex-class-component";
import { vxm } from "..";

const VuexModule = createModule({
  namespaced: "RoomKeys",
  strict: false,
});

export class RoomKeys extends VuexModule {
  public precaution = false;
  public firstKey = false;
  public room1Key = false;
  public room1LightSwitch = false;
  public room2Key = false;
  public room3Door = false;
  public room3Key = false;
  public enterRoom4 = false;
  public room4Door = false;
  public room4Key = false;
  public room5Key = false;
  public lastKey = false;
  public roomNum = 0;
  public room1Answer = "";
  public room2Answer = "";
  public room3Diff = 0;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public room4Input: any = null;

  @mutation
  public availablePrecaution() {
    this.precaution = true;
  }

  @mutation
  public availableFirstKey() {
    this.firstKey = true;
  }

  @mutation
  public availableRoom1LightSwitch() {
    this.room1LightSwitch = true;
  }

  @mutation
  public availableRoom1Key() {
    this.room1Key = true;
  }

  @mutation
  public availableRoom2Key() {
    this.room2Key = true;
  }

  @mutation
  public availableRoom3Door() {
    this.room3Door = true;
  }

  @mutation
  public availableRoom3Key() {
    this.room3Key = true;
  }

  @mutation
  private availableEnterRoom4() {
    this.enterRoom4 = true;
    vxm.controllContents.enterRoom4();
  }

  @mutation
  public availableRoom4Door() {
    this.room4Door = true;
  }

  @mutation
  public availableRoom4Key() {
    this.room4Key = true;
  }

  @mutation
  public availableRoom5Key() {
    this.room5Key = true;
  }

  @mutation
  public availableLastKey() {
    this.lastKey = true;
  }

  @action
  public async checkEnterRoom4State() {
    if (!this.enterRoom4) {
      this.availableEnterRoom4();
    }
  }

  @mutation
  public saveRoomNum(payload: number) {
    this.roomNum = payload;
  }
}
