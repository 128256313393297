







import { Component, Prop, Vue } from "vue-property-decorator";
import { vxm } from "@/store";

type Player = {
    id: number;
    name: string;
    value: boolean;
}

@Component
export default class LastPlayerButtonWrapper extends Vue {
    @Prop()
    private player!: Player;

    animateFrame = 0;
    nowTime = 0;
    diffTime = 0;
    startTime = 0;
    isRunning = false;
    secondNum = 0;
    stopTime = 0;
    clearFlg = false;

    mounted() {
        if(vxm.controllContents.wifiPass == "nazotokio2020" && this.player.id != 0) {
            const randomTime = Math.floor(Math.random() * (10 - 3) + 3) * 1000;
            setTimeout(this.changeTimer, randomTime);
        }
    }

    changeTimer() {
        this.player.value = !this.player.value;
        if (!this.isRunning) {
            this.startTimer();
        }

        if(!this.clearFlg && !this.player.value && this.player.id != 0){
            const randomTime = Math.floor(Math.random() * (10 - 3) + 3) * 1000
            setTimeout(this.changeTimer, randomTime)
        }
    }

    setSubtractStartTime(time: number) {
        this.startTime = Math.floor(performance.now() - time);
    }

    startTimer() {
        this.isRunning = true;
        this.stopTime = Math.floor(Math.random() * (10 - 3) + 3)
        this.setSubtractStartTime(this.diffTime);
        const loop = () => {
            if (!this.player.value) {
              this.clearAll();
              return;
            }
            this.nowTime = Math.floor(performance.now());
            this.diffTime = this.nowTime - this.startTime;
            this.seconds();
            requestAnimationFrame(loop);
        };
        if(!this.clearFlg) {
        requestAnimationFrame(loop);
        }
    }

    clearAll() {
      this.startTime = 0;
      this.nowTime = 0;
      this.diffTime = 0;
      this.secondNum = 0;
      this.animateFrame = 0;
      this.isRunning = false;
    }

    seconds() {
        const second = Math.floor(this.diffTime / 1000) % 60;
    
        if (vxm.controllContents.wifiPass != "nazotokio2020" && this.player.id != 0 && second >= 10 && !this.clearFlg) {
            this.clearFlg = true;
            this.$emit("clear", false);
        }

        /*
        * state = trueのとき
        * 親要素のid=8でsecond>=10のとき親要素toClearPage発火
        */
        if (vxm.controllContents.wifiPass != "nazotokio2020" && this.player.id == 0 && second >= 10 && !this.clearFlg) {
            this.clearFlg = true;
            this.$emit("clear", true);
        }
        /*
        * state = falseのとき
        * target.checked = falseにする
        */
        if (vxm.controllContents.wifiPass == "nazotokio2020") {
            if(second >= this.stopTime){
              this.player.value = false;
              const randomTime = Math.floor(Math.random() * (15 - 4) + 4) * 1000
              setTimeout(this.changeTimer, randomTime)
            }
        }
        this.secondNum = second;
    }
}
